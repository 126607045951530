import { BasePagination, IconButtonWithTooltip, PlusIcon } from '@elkaso-app/web-design';
import { useGetBranchListApi } from 'apis/restaurants/use-get-branch-list-api';
import { useGetRestaurantByIdApi } from 'apis/restaurants/use-get-restaurant-by-id-api';
import { BaseTable, BaseTableContainer } from 'components/base-table';
import {
  BaseTableSpinner,
  BaseTableTitle,
  ClearFilterButton,
  CustomTableCell,
  DateTimeCell,
  IDateTimeCell,
  TableCell,
  TableHeader,
} from 'components/base-table/components';
import { NameFilterButton } from 'components/base-table/filters';
import { BaseSortButton } from 'components/base-table/sorting';
import { Can } from 'configs/can';
import { useSetPaginationParamsV1 } from 'hooks/use-set-pagination-params-v1';
import { AddBranchModal } from 'pages/restaurants/components/add-branch-modal';
import { BranchActionsCell, IBranchActionsCell } from 'pages/restaurants/components/branch-actions-cell';
import { BranchNameCell, IBranchNameCell } from 'pages/restaurants/components/branch-name-cell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

export const BranchListScreen = () => {
  const setPaginationParams = useSetPaginationParamsV1();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { isLoading, isFetching, data } = useGetBranchListApi(restaurantId);
  const { pageCount, total, data: branchesData } = data ?? {};
  const { data: restaurantData } = useGetRestaurantByIdApi(restaurantId);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableHeader
            name='Branch Name'
            filterButtonComponent={<NameFilterButton />}
            sortButtonComponent={<BaseSortButton sortBy='name' />}
          />
        ),
        accessor: 'name',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['name']>) => (
          <CustomTableCell<IBranchNameCell> as={BranchNameCell} branchName={value?.name} branchId={value?.id} />
        ),
      },
      {
        Header: <TableHeader name='Email' />,
        accessor: 'email',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['email']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Landline Number' />,
        accessor: 'landlineNumber',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['landlineNumber']>) => <TableCell value={value} />,
      },
      {
        Header: <TableHeader name='Creation Date' />,
        accessor: 'creation_date',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['creation_date']>) => (
          <CustomTableCell<IDateTimeCell> as={DateTimeCell} date={value} />
        ),
      },
      {
        Header: <TableHeader name='Actions' />,
        accessor: 'actions',
        Cell: ({ value }: CellProps<any, IColumnsAccessorData['actions']>) => (
          <CustomTableCell<IBranchActionsCell> as={BranchActionsCell} branchId={value} />
        ),
      },
    ],
    []
  );

  interface IColumnsAccessorData {
    id: number;
    name: {
      id: string;
      name: string;
    };
    email: string;
    landlineNumber: string;
    creation_date: string;
    actions: any;
  }

  const columnsAccessorData = React.useMemo(
    () =>
      branchesData?.map((branch: (typeof branchesData)[0]): IColumnsAccessorData => {
        return {
          id: branch?.id,
          name: {
            id: branch?.id,
            name: branch?.name?.en,
          },
          email: branch?.email,
          landlineNumber: branch?.landlineNumber,
          creation_date: branch?.createdAt,
          actions: branch?.id,
        };
      }),
    [branchesData]
  );

  return (
    <BaseTableContainer isLoading={isLoading}>
      <BaseTableContainer.Header>
        <BaseTableContainer.Header.Left>
          <BaseTableTitle title={restaurantData?.data?.name} />

          <BaseTableSpinner isFetching={isFetching} />
        </BaseTableContainer.Header.Left>

        <BaseTableContainer.Header.Right>
          <ClearFilterButton />

          <Can I='update' a='User'>
            <AddBranchModal
              variant='add'
              trigger={
                <IconButtonWithTooltip
                  label='Add branch'
                  aria-label='add-branch-btn'
                  Icon={<PlusIcon />}
                  disabled={branchesData?.length >= 4}
                />
              }
            />
          </Can>
        </BaseTableContainer.Header.Right>
      </BaseTableContainer.Header>

      <BaseTableContainer.TableWrapper>
        <BaseTable columns={columns} data={columnsAccessorData} />
      </BaseTableContainer.TableWrapper>

      <BaseTableContainer.Footer>
        <BaseTableContainer.Footer.Left />
        <BaseTableContainer.Footer.Right>
          <BasePagination>
            <BasePagination.Pages pagesCount={pageCount} setSearchParams={setPaginationParams} />
            <BasePagination.Limit total={total} setSearchParams={setPaginationParams} />
          </BasePagination>
        </BaseTableContainer.Footer.Right>
      </BaseTableContainer.Footer>
    </BaseTableContainer>
  );
};
