import {
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useCreateBranchApi } from 'apis/restaurants/use-create-branch-api';
import { useGetRestaurantBranchByIdApi } from 'apis/restaurants/use-get-restaurant-branch-by-id-api';
import { useUpdateBranchApi } from 'apis/restaurants/use-update-branch-api';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type TInputs = {
  name: {
    en: string;
    ar: string;
  };
  email: string | null;
  landlineNumber: string | null;
  coordinates?: {
    lat: string;
    lng: string;
  };
  address: string | null;
};

const defaultValues: TInputs = {
  name: {
    en: '',
    ar: '',
  },
  email: '',
  landlineNumber: '',
  coordinates: {
    lat: '',
    lng: '',
  },
  address: '',
};

export interface IAddBranchModalCell extends BoxProps {
  variant: 'add' | 'edit';
  trigger: JSX.Element;
  branchId?: string;
}

export const AddBranchModal = ({ variant, trigger, branchId = '' }: IAddBranchModalCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { isLoading: isCreateBranchLoading, mutate: createBranch } = useCreateBranchApi(restaurantId);
  const { isLoading: isUpdateBranchLoading, mutate: updateBranch } = useUpdateBranchApi(restaurantId);

  const isEdit = useMemo(() => {
    if (branchId && variant === 'edit') return true;
    return false;
  }, [branchId, variant]);

  const {
    isLoading: isBranchLoading,
    data: branch,
    refetch: refetchBranchData,
  } = useGetRestaurantBranchByIdApi(restaurantId, branchId, {
    enabled: isEdit,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  // Update values
  const updateValues = () => {
    setValue('name.en', branch?.name?.en ?? '');
    setValue('name.ar', branch?.name?.ar ?? '');

    setValue('address', branch?.address ?? '');

    setValue('email', branch?.email ?? '');

    setValue('landlineNumber', branch?.landlineNumber ?? '');
  };

  useEffect(() => {
    updateValues();
  }, [isOpen]);

  const onSubmit = (data: TInputs) => {
    // Create new branch
    if (variant === 'add') {
      const variables = {
        body: {
          name: {
            en: data.name.en,
            ar: data.name.ar,
          },
          address: data.address !== '' ? data.address : null,
          email: data.email !== '' ? data.email : null,
          landlineNumber: data.landlineNumber !== '' ? data.landlineNumber : null,
        },
      };

      createBranch(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    // Edit existing branch
    if (variant === 'edit') {
      const variables = {
        branchId: branch?.id,
        body: {
          name: {
            en: data.name.en,
            ar: data.name.ar,
          },
          address: data.address !== '' ? data.address : null,
          email: data.email !== '' ? data.email : null,
          landlineNumber: data.landlineNumber !== '' ? data.landlineNumber : null,
        },
      };

      updateBranch(variables, {
        onSuccess: () => {
          refetchBranchData();
          onClose();
        },
      });
    }
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick: onOpen })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => reset(defaultValues)}
        closeOnOverlayClick={false}
        size='3xl'>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              {!isEdit && (
                <>
                  <Heading as='h2' size='lg'>
                    Add New Branch
                  </Heading>
                  <Text color='gray.500' fontWeight='normal' fontSize='md'>
                    Fill the form below to create a new branch.
                  </Text>
                </>
              )}

              {!!isEdit && (
                <>
                  <Heading as='h2' size='lg'>
                    Edit Branch Details
                  </Heading>
                  <Text color='gray.500' fontWeight='normal' fontSize='md'>
                    Make sure to save the changes after editing the branch details.
                  </Text>
                </>
              )}
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack as='form' id='addCategoryModal' spacing='md' onSubmit={handleSubmit(onSubmit)} noValidate>
              <HStack align='start'>
                <FormControl isRequired isInvalid={errors?.name?.en ? true : false}>
                  <FormLabel>Branch Name (En)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='ex. Branch 1'
                    {...register('name.en', {
                      required: 'English name is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.name?.en?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.name?.ar ? true : false}>
                  <FormLabel>Branch Name (Ar)</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='مثال: مخزن 1'
                    dir='rtl'
                    textAlign='start'
                    {...register('name.ar', {
                      pattern: {
                        value: /^[\u0600-\u06FF\s0-9\u0660-\u0669\u06F0-\u06F9]+$/,
                        message: 'Please enter Arabic letters only.',
                      },
                    })}
                  />

                  <FormErrorMessage>{errors.name?.ar?.message}</FormErrorMessage>
                </FormControl>
              </HStack>

              <HStack align='start'>
                <FormControl isRequired isInvalid={errors?.address ? true : false}>
                  <FormLabel>Address</FormLabel>
                  <Input
                    variant='outline'
                    placeholder='ex. Branch address 1'
                    {...register('address', {
                      required: 'Address is required.',
                    })}
                  />

                  <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors?.landlineNumber ? true : false}>
                  <FormLabel>Landline Number</FormLabel>
                  <Input variant='outline' placeholder='ex. 04 888 2225' {...register('landlineNumber')} />

                  <FormErrorMessage>{errors.landlineNumber?.message}</FormErrorMessage>
                </FormControl>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing='md'>
              <Button variant='ghost' colorScheme='gray' onClick={onClose}>
                Cancel
              </Button>

              <Button
                variant='solid'
                colorScheme='red'
                type='submit'
                form='addCategoryModal'
                isLoading={isCreateBranchLoading || isUpdateBranchLoading || isBranchLoading}>
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
