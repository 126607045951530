import { HStack, IconButtonWithTooltip } from '@elkaso-app/web-design';
import { Can } from 'configs/can';
import { AddBranchModal } from 'pages/restaurants/components/add-branch-modal';
import { useHistory, useParams } from 'react-router-dom';
import { EditIcon } from '../icons/edit-icon';
import { TruckIcon } from '../icons/truck-icon';
import { UsersIcon } from '../icons/users-icon';

export interface IBranchActionsCell {
  branchId: any;
}

export const BranchActionsCell = ({ branchId }: IBranchActionsCell) => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const history = useHistory();

  const onUsersClick = () => {
    history.push(`/app/restaurants/branch/users/${branchId}`);
  };

  const onSuppliersClick = () => {
    history.push(`/app/restaurants/branch/suppliers/${restaurantId}/${branchId}/branch`);
  };

  return (
    <HStack spacing='xs'>
      <IconButtonWithTooltip
        Icon={<UsersIcon boxSize='20px' />}
        label='Users'
        aria-label='Users'
        variant='ghost'
        size='sm'
        onClick={onUsersClick}
      />

      <IconButtonWithTooltip
        Icon={<TruckIcon boxSize='18px' />}
        label='Suppliers'
        aria-label='Suppliers'
        variant='ghost'
        size='sm'
        onClick={onSuppliersClick}
      />

      <Can I='update' a='User'>
        <AddBranchModal
          variant='edit'
          branchId={branchId}
          trigger={
            <IconButtonWithTooltip
              Icon={<EditIcon boxSize='15px' />}
              label='Edit'
              aria-label='Edit'
              variant='ghost'
              size='sm'
            />
          }
        />
      </Can>
    </HStack>
  );
};
