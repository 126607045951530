import {
  AsyncRestaurantKpiComponent,
  AsyncRestaurantsFormComponent,
  AsyncRestaurantsListComponent,
  AsyncRestaurantSuppliersListComponent,
  AsyncRestaurantUsersFormComponent,
  AsyncRestaurantUsersListComponent,
  RestaurantBranchesSuppliersList,
  RestaurantDetails,
  RestaurantManagersListComponent,
  RestaurantMergePage,
  RestaurantOrdersList,
  RestaurantSupplierEmails,
} from 'components/AsyncComponent';
import { Layout, Section } from 'layout';
import { BranchSupplierItemsScreen } from 'pages/restaurants/screens/branch-supplier-items-screen';
import { DocumentsScreen } from 'pages/restaurants/screens/documents-screen';
import { RestaurantSupplierItemsScreen } from 'pages/restaurants/screens/restaurant-supplier-items-screen';
import { RestaurantUsersScreen } from 'pages/restaurants/screens/restaurant-users-screen';
import { SupplierSAMsScreen } from 'pages/restaurants/screens/supplier-sams-screen';
import { WarehouseListScreen } from 'pages/restaurants/screens/warehouse-list-screen';
import { WarehouseProductsScreen } from 'pages/restaurants/screens/warehouse-products-screen';
import { WarehouseSupplierListScreen } from 'pages/restaurants/screens/warehouse-suppliers-list-screen';
import { WarehouseSupplierProductsScreen } from 'pages/restaurants/screens/warehouse-suppliers-products-screen';
import { WarehouseUsersScreen } from 'pages/restaurants/screens/warehouse-users-screen';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AllDocumentList } from './components/restaurant-licenses/all-document-list';
import { PendingRequestList } from './components/restaurant-licenses/pending-request-list';
import { BranchListScreen } from './screens/branch-list-screen';

const RestaurantsPage = () => {
  const match = useRouteMatch();

  return (
    <Layout type={'sidebar_page_layout'}>
      <Section name={'content'}>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
          <Route path={`${match.url}/list`} component={AsyncRestaurantsListComponent} />
          <Route path={`${match.url}/edit/:id`} component={AsyncRestaurantsFormComponent} />
          <Route exact path={`${match.url}/supplier/sams/:restaurantId/:supplierId`} component={SupplierSAMsScreen} />
          <Route path={`${match.url}/restaurant-users/:restaurantId`} component={RestaurantUsersScreen} />

          <Route
            path={`${match.url}/supplier/products/:restaurantId/:supplierId`}
            component={RestaurantSupplierItemsScreen}
          />

          <Route path={`${match.url}/supplier/:id/:countryCode`} component={AsyncRestaurantSuppliersListComponent} />

          <Route
            path={`${match.url}/branch/suppliers/:restaurantId/:subEntityId/:subEntityType`}
            component={RestaurantBranchesSuppliersList}
          />
          <Route path={`${match.url}/branch/users/:id`} component={RestaurantManagersListComponent} />

          <Route
            path={`${match.url}/branch-suppliers/products/:restaurantId/:branchId/:supplierId`}
            component={BranchSupplierItemsScreen}
          />

          <Route
            path={`${match.url}/suppliers/emails/:restaurantId/:supplierId`}
            component={RestaurantSupplierEmails}
          />
          <Route path={`${match.url}/kpi/:id`} component={AsyncRestaurantKpiComponent} />
          <Route exact path={`${match.url}/users/:id`} component={AsyncRestaurantUsersListComponent} />
          <Route path={`${match.url}/users/edit/:id`} component={AsyncRestaurantUsersFormComponent} />
          <Route path={`${match.url}/merge/:restaurantId`} component={RestaurantMergePage} />
          <Route path={`${match.url}/orders-list/:restaurantId`} component={RestaurantOrdersList} />
          <Route path={`${match.url}/details/:id`} component={RestaurantDetails} />

          {/* Branch */}
          <Route path={`${match.url}/branch/list/:restaurantId`} component={BranchListScreen} />

          {/* Warehouse */}
          <Route path={`${match.url}/warehouse/list/:restaurantId`} component={WarehouseListScreen} />
          <Route path={`${match.url}/warehouse/users/:restaurantId/:warehouseId`} component={WarehouseUsersScreen} />

          <Route path={`${match.url}/warehouse/items/:restaurantId/:warehouseId`} component={WarehouseProductsScreen} />

          <Route
            path={`${match.url}/warehouse/suppliers/items/:restaurantId/:warehouseId/:supplierId`}
            component={WarehouseSupplierProductsScreen}
          />

          <Route
            path={`${match.url}/warehouse/suppliers/:restaurantId/:warehouseId`}
            component={WarehouseSupplierListScreen}
          />

          {/* Documents */}
          <Route exact path={`${match.url}/documents`} component={DocumentsScreen} />
          <Route path={`${match.url}/documents/all`} component={AllDocumentList} />
          <Route path={`${match.url}/documents/pending-request`} component={PendingRequestList} />

          {/* 404 */}
          <Route path='*' render={() => <Redirect to='/404' />} />
        </Switch>
      </Section>
    </Layout>
  );
};

export default RestaurantsPage;
