import {
  Box,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  TextProps,
  useDisclosure,
  VStack,
} from '@elkaso-app/web-design';
import { useGetRestaurantBranchByIdApi } from 'apis/restaurants/use-get-restaurant-branch-by-id-api';
import { useParams } from 'react-router-dom';

const Title = (props: TextProps) => {
  return <Text fontWeight='semibold' color='gray.600' {...props} />;
};

const Info = (props: TextProps) => {
  return <Text {...props} />;
};

export interface IBranchNameCell {
  branchId: string;
  branchName: string;
}

export const BranchNameCell = ({ branchName, branchId }: IBranchNameCell) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const { data: branch } = useGetRestaurantBranchByIdApi(restaurantId, branchId);

  return (
    <>
      <Text fontWeight='semibold' color='red.500' cursor='pointer' onClick={onOpen}>
        {branchName}
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <VStack align='start' spacing='xs'>
              <Heading as='h2' size='lg'>
                Branch Details
              </Heading>
              <Text color='gray.500' fontWeight='normal' fontSize='md'>
                You are viewing branch details.
              </Text>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack spacing='md'>
              <HStack align='start'>
                <Box flex={1}>
                  <Title>Branch Name (En)</Title>
                  <Info>{branch?.name?.en}</Info>
                </Box>

                <Box flex={1}>
                  <Title>Branch Name (Ar)</Title>
                  <Info>{branch?.name?.ar}</Info>
                </Box>
              </HStack>

              <HStack align='start'>
                <Box flex={1}>
                  <Title>Address</Title>
                  <Info>{branch?.address}</Info>
                </Box>

                <Box flex={1}>
                  <Title>Landline Number</Title>
                  <Info>{branch?.landlineNumber}</Info>
                </Box>
              </HStack>

              <HStack align='start'>
                <Box flex={1}>
                  <Title>Email</Title>
                  <Info>{branch?.email}</Info>
                </Box>
              </HStack>
            </Stack>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
